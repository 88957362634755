#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  flex-grow: 1;
}

html,
div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

div::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}
